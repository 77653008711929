var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex justify-content-end p-2 edit-field",
    class: {
      'total-text': _vm.item.item.is_total
    },
    attrs: {
      "contenteditable": !_vm.item.item.is_total
    },
    on: {
      "blur": _vm.blurTextEdit,
      "input": _vm.setPlan,
      "focus": _vm.clickTextEdit
    }
  }, [_vm._v(" 0,00 ")]);

}
var staticRenderFns = []

export { render, staticRenderFns }