var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "d-flex pb-2"
  }, [_c('e-select', {
    staticStyle: {
      "width": "90px"
    },
    attrs: {
      "selected_type": "text",
      "options": _vm.yearOptions,
      "serchable": false,
      "dropdown_width": 90,
      "value": [{
        id: _vm.year
      }]
    },
    on: {
      "change": _vm.setYear
    }
  })], 1), _c('resizable-table', {
    staticClass: "bdr-table",
    attrs: {
      "first_fixed": "",
      "table_name": "bdr_table",
      "foot": 2,
      "foot_row": false,
      "default_fields": _vm.fields,
      "items": _vm.items_list
    },
    scopedSlots: _vm._u([{
      key: "body_name",
      fn: function (item) {
        return [_c('div', {
          staticClass: "d-flex",
          class: {
            'total-text': item.item.is_total
          }
        }, [_vm._v(" " + _vm._s(item.value) + " ")])];
      }
    }, {
      key: "body_builder_plan",
      fn: function (item) {
        return [_c('bdr-edit-field', {
          ref: item.parent.key + '.' + _vm.year + item.item.id,
          attrs: {
            "item": item
          },
          on: {
            "blur": _vm.blurTextEdit,
            "input": function (e) {
              return _vm.setPlan(item, e);
            },
            "focus": function (e) {
              return _vm.clickTextEdit(e, item.parent.key + '.' + _vm.year + item.item.id);
            }
          }
        })];
      }
    }, {
      key: "body_builder_fact",
      fn: function (item) {
        var _vm$items, _vm$items$item$item$i;

        return [_c('div', {
          staticClass: "d-flex justify-content-end p-2",
          class: {
            'total-text': item.item.is_total
          }
        }, [_vm._v(" " + _vm._s(_vm.formatMoney((_vm$items = _vm.items[item.parent.key + '.' + _vm.year]) === null || _vm$items === void 0 ? void 0 : (_vm$items$item$item$i = _vm$items[item.item.id]) === null || _vm$items$item$item$i === void 0 ? void 0 : _vm$items$item$item$i.fact)) + " ")])];
      }
    }, {
      key: "foot_name",
      fn: function (item) {
        return [_c('div', {
          staticClass: "d-flex foot-total-text"
        }, [_vm._v("Итого " + _vm._s(item.index === 0 ? 'за месяц ' : ''))])];
      }
    }, {
      key: "foot_0_body_builder_plan",
      fn: function (item) {
        var _vm$items2;

        return [_c('div', {
          staticClass: "d-flex justify-content-end p-2 foot-total-text",
          class: {
            'total-text': item.item.is_total
          },
          staticStyle: {
            "line-height": "16px"
          }
        }, [_vm._v(" " + _vm._s(_vm.formatMoney((_vm$items2 = _vm.items[item.parent.key + '.' + _vm.year]) === null || _vm$items2 === void 0 ? void 0 : _vm$items2.sum_plan)) + " ")])];
      }
    }, {
      key: "foot_0_body_builder_fact",
      fn: function (item) {
        var _vm$items3;

        return [_c('div', {
          staticClass: "d-flex justify-content-end p-2 foot-total-text",
          class: {
            'total-text': item.item.is_total
          },
          staticStyle: {
            "line-height": "16px"
          }
        }, [_vm._v(" " + _vm._s(_vm.formatMoney((_vm$items3 = _vm.items[item.parent.key + '.' + _vm.year]) === null || _vm$items3 === void 0 ? void 0 : _vm$items3.sum_fact)) + " ")])];
      }
    }, {
      key: "foot_1_body_builder_plan",
      fn: function (item) {
        var _vm$items4;

        return [_c('div', {
          staticClass: "d-flex justify-content-end p-2 foot-total-text",
          class: {
            'total-text': item.item.is_total
          },
          staticStyle: {
            "line-height": "16px"
          }
        }, [_vm._v(" " + _vm._s(_vm.formatMoney((_vm$items4 = _vm.items[item.parent.key + '.' + _vm.year]) === null || _vm$items4 === void 0 ? void 0 : _vm$items4.cum_sum_plan)) + " ")])];
      }
    }, {
      key: "foot_1_body_builder_fact",
      fn: function (item) {
        var _vm$items5;

        return [_c('div', {
          staticClass: "d-flex justify-content-end p-2 foot-total-text",
          class: {
            'total-text': item.item.is_total
          },
          staticStyle: {
            "line-height": "16px"
          }
        }, [_vm._v(" " + _vm._s(_vm.formatMoney((_vm$items5 = _vm.items[item.parent.key + '.' + _vm.year]) === null || _vm$items5 === void 0 ? void 0 : _vm$items5.cum_sum_fact)) + " ")])];
      }
    }])
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }