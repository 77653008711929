<template>
  <div>
    <div class="d-flex pb-2">
      <e-select
        selected_type="text"
        style="width: 90px"
        :options="yearOptions"
        :serchable="false"
        :dropdown_width="90"
        :value="[{ id: year }]"
        @change="setYear"
      />
    </div>
    <resizable-table
      first_fixed
      table_name="bdr_table"
      class="bdr-table"
      :foot="2"
      :foot_row="false"
      :default_fields="fields"
      :items="items_list"
    >
      <template #body_name="item">
        <div
          class="d-flex"
          :class="{ 'total-text': item.item.is_total }"
        >
          {{ item.value }}
        </div>
      </template>
      <template #body_builder_plan="item">
        <bdr-edit-field
          :ref="item.parent.key + '.' + year + item.item.id"
          :item="item"
          @blur="blurTextEdit"
          @input="(e) => setPlan(item, e)"
          @focus="(e) => clickTextEdit(e, item.parent.key + '.' + year + item.item.id)"
        />
      </template>
      <template #body_builder_fact="item">
        <div
          class="d-flex justify-content-end p-2"
          :class="{ 'total-text': item.item.is_total }"
        >
          {{ formatMoney(items[item.parent.key + '.' + year]?.[item.item.id]?.fact) }}
        </div>
      </template>
      <template #foot_name="item">
        <div class="d-flex foot-total-text">Итого {{ item.index === 0 ? 'за месяц ' : '' }}</div>
      </template>
      <template #foot_0_body_builder_plan="item">
        <div
          class="d-flex justify-content-end p-2 foot-total-text"
          style="line-height: 16px"
          :class="{ 'total-text': item.item.is_total }"
        >
          {{ formatMoney(items[item.parent.key + '.' + year]?.sum_plan) }}
        </div>
      </template>
      <template #foot_0_body_builder_fact="item">
        <div
          class="d-flex justify-content-end p-2 foot-total-text"
          style="line-height: 16px"
          :class="{ 'total-text': item.item.is_total }"
        >
          {{ formatMoney(items[item.parent.key + '.' + year]?.sum_fact) }}
        </div>
      </template>
      <template #foot_1_body_builder_plan="item">
        <div
          class="d-flex justify-content-end p-2 foot-total-text"
          style="line-height: 16px"
          :class="{ 'total-text': item.item.is_total }"
        >
          {{ formatMoney(items[item.parent.key + '.' + year]?.cum_sum_plan) }}
        </div>
      </template>
      <template #foot_1_body_builder_fact="item">
        <div
          class="d-flex justify-content-end p-2 foot-total-text"
          style="line-height: 16px"
          :class="{ 'total-text': item.item.is_total }"
        >
          {{ formatMoney(items[item.parent.key + '.' + year]?.cum_sum_fact) }}
        </div>
      </template>
    </resizable-table>
  </div>
</template>
<script>
  import ResizableTable from '@/components/ResizableTable'
  import { formatMoney } from '@/utils/formatMoney'
  import { mapGetters } from 'vuex'
  import BdrEditField from '@/views/payment/components/BdrEditField.vue'

  export default {
    name: 'Bdr',
    components: {
      BdrEditField,
      ResizableTable
    },
    apollo: {
      ReadBudgetItem: {
        query: require('../gql/ReadBudgetItem.graphql'),
        result({ data }) {
          this.items_list = [
            {
              type: 'inc_total',
              rowTableColor: '#EBEBEB',
              rowTextColor: '#313131',
              is_total: true,
              rowTextWeight: '600',
              id: 'inc_total',
              name: 'Доход'
            },
            ...data.ReadBudgetItem.filter((el) => el.type === 'inc'),
            {
              type: 'out_total',
              is_total: true,
              rowTableColor: '#EBEBEB',
              rowTextColor: '#313131',
              rowTextWeight: '600',
              id: 'out_total',
              name: 'Расход'
            },
            ...data.ReadBudgetItem.filter((el) => el.type === 'out')
          ]
          console.log(data)
        },
        variables() {
          return {
            branch: this.currentBranch.id
          }
        }
      },
      BudgetList: {
        query: require('../gql/BudgetList.graphql'),
        fetchPolicy: 'no-cache',
        variables() {
          return {
            branch: this.currentBranch.id,
            period: { begin: this.year + '-01-01', end: +this.year + 1 + '-01-01' }
          }
        },
        result({ data }) {
          const new_items = {}
          const setItem = (month) => {
            month.date = (month.month + '').padStart(2, '0') + '.' + month.year
            new_items[month.date] = {
              cum_sum_plan: month.cum_sum_plan / 100,
              cum_sum_fact: month.cum_sum_fact / 100,
              sum_plan: (month.inc_sum_plan - month.out_sum_plan) / 100,
              sum_fact: (month.inc_sum_fact - month.out_sum_fact) / 100,
              inc_total: {
                plan: month.inc_sum_plan / 100,
                fact: month.inc_sum_fact / 100
              },
              out_total: {
                plan: month.out_sum_plan / 100,
                fact: month.out_sum_fact / 100
              }
            }

            for (const item of month.items) {
              new_items[month.date][item.item.id] = {
                ...item.item,
                plan: item.plan / 100,
                fact: item.fact / 100
              }
              if (this.$refs[month.date + item.item.id]) {
                if (!this.$refs[month.date + item.item.id].$data.is_focused) {
                  this.$refs[month.date + item.item.id].$el.innerHTML = formatMoney(item.plan / 100, 2)
                }
              }
            }
          }
          data.BudgetList?.list.forEach(setItem)
          console.log(new_items)
          this.items = new_items
        }
      }
    },
    data() {
      return {
        items: {},
        timer: null,
        year: '2024',
        currentValue: {
          id: null,
          value: null
        },
        yearOptions: [
          { id: '2022', name: '2022' },
          { id: '2023', name: '2023' },
          { id: '2024', name: '2024' },
          { id: '2025', name: '2025' },
          { id: '2026', name: '2026' }
        ],
        items_list: [],
        fields: [
          {
            key: 'name',
            checked: true,
            label: 'Статья',
            class: 'right-border',
            width: 300
          },
          ...[
            { name: '01', label: 'январь' },
            { name: '02', label: 'февраль' },
            { name: '03', label: 'март' },
            { name: '04', label: 'апрель' },
            { name: '05', label: 'май' },
            { name: '06', label: 'июнь' },
            { name: '07', label: 'июль' },
            { name: '08', label: 'август' },
            { name: '09', label: 'сентябрь' },
            { name: '10', label: 'октябрь' },
            { name: '11', label: 'ноябрь' },
            { name: '12', label: 'декабрь' }
          ].map((el) => {
            return {
              key: el.name,
              checked: true,
              label: el.label,
              width: 220,
              children: [
                {
                  key: 'plan',
                  checked: true,
                  label: 'План',
                  width: 110,
                  slot_builder_name: 'body_builder_plan',
                  class: 'p-0'
                },
                {
                  key: 'fact',
                  checked: true,
                  label: 'Факт',
                  width: 110,
                  class: 'p-0  right-border',
                  slot_builder_name: 'body_builder_fact'
                }
              ]
            }
          })
        ]
      }
    },
    computed: {
      ...mapGetters({
        currentBranch: 'settings/getCurrentBranch'
      })
    },
    methods: {
      formatMoney(value) {
        return formatMoney(value, 2)
      },
      toNumeric(value) {
        return +(value || '').replaceAll(',', '.').replaceAll(' ', '').replaceAll(' ', '')
      },
      blurTextEdit(e) {
        this.currentValue = {
          id: null,
          value: null
        }
        e.target.innerText = this.formatMoney(this.toNumeric(e.target.innerText))
      },
      clickTextEdit(e, id) {
        this.currentValue = { value: e.target.innerText, id }
        console.log(e)
        setTimeout(() => {
          var range, selection
          if (document.body.createTextRange) {
            range = document.body.createTextRange()
            range.moveToElementText(e.target)
            range.select()
          } else if (window.getSelection) {
            selection = window.getSelection()
            range = document.createRange()
            range.selectNodeContents(e.target)
            selection.removeAllRanges()
            selection.addRange(range)
          }
        }, 1)
      },
      setPlan(item, e) {
        this.currentValue.value = e.target.innerText
        clearTimeout(this.timer)
        this.timer = setTimeout(async () => {
          await this.$apollo.mutate({
            mutation: require('../gql/PlanBudgetItem.graphql'),
            variables: {
              input: {
                branch: this.currentBranch.id,
                year: +this.year,
                month: +item.parent.key,
                item: item.item.id,
                plan: +(this.toNumeric(e.target.innerText) * 100).toFixed()
              }
            }
          })
          await this.$apollo.queries.BudgetList.refetch()
        }, 300)
      },
      setYear(value) {
        this.year = value[0].id
      }
    }
  }
</script>
<style scoped lang="scss">
  .total-text {
    color: #313131;
    font-weight: 600;
  }
  .edit-field {
    border: 1px transparent solid;
    white-space: nowrap;
    overflow: hidden;
    text-align: right;
  }
  .edit-field:focus {
    border: 1px #00a3ff solid !important;
    border-radius: 2px;
  }
  .foot-total-text {
    padding: 10px 16px;
    display: flex;
    font-weight: 600;
  }
  ::v-deep {
    .right-border {
      border-right: 1px solid rgb(225, 225, 225);
    }
    .table-inner table th {
      border: none;
    }
    .table-inner table tfoot tr td {
      padding: 0px !important;
      line-height: 16px;
    }
  }
</style>
