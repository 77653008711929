<template>
  <div
    :contenteditable="!item.item.is_total"
    class="d-flex justify-content-end p-2 edit-field"
    :class="{ 'total-text': item.item.is_total }"
    @blur="blurTextEdit"
    @input="setPlan"
    @focus="clickTextEdit"
  >
    0,00
  </div>
</template>
<script>
  export default {
    name: 'BdrEditField',
    props: {
      item: {
        type: Object,
        default: () => {}
      }
    },
    data() {
      return {
        is_focused: false
      }
    },
    methods: {
      blurTextEdit(e) {
        this.is_focused = false
        this.$emit('blur', e)
      },
      setPlan(e) {
        this.is_focused = true
        this.$emit('input', e)
      },
      clickTextEdit(e) {
        this.is_focused = true
        this.$emit('focus', e)
      }
    }
  }
</script>

<style scoped lang="scss">
  .edit-field {
    border: 1px transparent solid;
    white-space: nowrap;
    overflow: hidden;
    text-align: right;
  }
  .edit-field:focus {
    border: 1px #00a3ff solid !important;
    border-radius: 2px;
  }
</style>
